import * as React from "react"
import { Link } from "gatsby"

const BreadCrumbs = ({crumbs, textColor}) => {

    return(
        <>  
         <section className={textColor ? 'section_breadcrumb ' : "section_breadcrumb BG-F9FAFE"} style={{color: textColor ? textColor : ''}}>
            <div className="container">
            <ul className="ul_breadcrumb">
                <li><Link exact to="/" style={{color: textColor ? textColor : ''}}>Home</Link></li>
                <li>{crumbs}</li>
            </ul>
            </div>
        </section>          
        </>
    )

}

export default BreadCrumbs