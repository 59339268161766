import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import OurlatestTwoSection from "../components/sections/ourLatestTwoPostsBlock"
import IndustrySection from "../components/homeSection/industryProwessBlock"
import GetInTouch from "../components/GetInTouch"
import BreadCrumbs from "../components/breadCrumbs";
import Head from '../components/head'

const ServicesPage = ( {data} ) => {
    const [verticalActive, setVerticalActive] = useState('services0');
    const service = data.UI.dsService
    const handleVerticalClick = (value) => {
        if (value === verticalActive) {
          return;
        }    
        setVerticalActive(value);
    };
    const OurServices = data.UI.dsService.OurServices
    const OurDeliverables = data.UI.dsService.OurDeliverables
    
    return (
        <Layout>
            <Head SEO_Tags={service.SeoTag} />
            <BreadCrumbs crumbs="Services" /> 
            <section className="hero_services tB-padd-30 BG-F9FAFE">
                <div className="container">
                    <div className="services_hero_content" data-sal="slide-right" style={{'--sal-duration':`1s`, '--sal-delay':`0.10s`}}>
                        {service.Description ? (
                            <div dangerouslySetInnerHTML={{ __html: service.Description }} />
                        ) : null}                        
                    </div>      
                </div>
            </section>  
            <section className="section_our_services tB-padd-30 BG-F9FAFE">
                <div className="container">
                    <h2 className="h2Bluetitle margin-bottom-40">Our Services</h2>    
                    <div className="row">
                        <div className="col-lg-3 ourServices_leftCol">
                            <div className="col_services_leftCol">
                                <ul className="ul_services_left">
                                    {OurServices && OurServices.map((tab, i) => (
                                        <li key={i}>
                                            <a className={verticalActive === `services${i}` ? "active" : null} href={`#services${i}`} onClick={() => handleVerticalClick(`services${i}`)}>
                                                {tab.Approach_Heading}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>                        
                        </div>
                        <div className="col-lg-9 ourServices_rightCol">
                            <div className="ourServices_leftInner">
                                {OurServices && OurServices.map((list, i) => (
                                    <div className={`services${i}_section serviceTile_container`} id={`services${i}`}>
                                        {list.Approach_Heading ? (<h3>{list.Approach_Heading}</h3>) : null}
                                        {list.Approach_Text ? (
                                            <p dangerouslySetInnerHTML={{ __html: list.Approach_Text }} />
                                        ) : null}
                                        <div className="row servicTtiles">
                                            {list.ApproachLists && list.ApproachLists.map((lists, c) => (
                                                <div className="col-md-4 col-sm-6 service_tile_col" key={c}>
                                                    <div className="serviceTile_Inner">
                                                        {lists.Approach_IconNum ? (
                                                            <div className="service_img">
                                                                <img src={lists.Approach_IconNum.url} alt={lists.Approach_IconNum.alternativeText} />    
                                                            </div>
                                                        ) : null}
                                                        {lists.Approach_Title ? (<h3>{lists.Approach_Title}</h3>) : null}
                                                        {lists.Approach_Description ? (
                                                            <p dangerouslySetInnerHTML={{ __html: lists.Approach_Description }} />
                                                        ) : null}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section> 
            <IndustrySection />     
            <section className="section_deliverables BG-F9FAFE tB-padd-30">
                <div className="container container-modified">
                    <h2 className="h2Bluetitle margin-bottom-40">Our Deliverables</h2>
                    <div className="row">
                        {OurDeliverables && OurDeliverables.map((delivery, i) => (
                            <div className="col-md-6 col-sm-6 col_deliverable" key={i}>
                                <div className="deliverable_col_inner">
                                    {delivery.OurDelivery_Heading ? (
                                        <h3>{delivery.OurDelivery_Heading}</h3>
                                    ) : null}
                                    {delivery.OurDelivery_ShortDescription ? (
                                        <p dangerouslySetInnerHTML={{ __html: delivery.OurDelivery_ShortDescription }} />
                                    ) : null}
                                    <div className="desc_chart">
                                        {delivery.OurDeliveries && delivery.OurDeliveries.map((deliveryList, i) => (
                                            <div className="desc_row">
                                                <div className="desc_col_left">{deliveryList.Heading}</div>
                                                <div className="desc_col_right">{deliveryList.Text}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}                        
                    </div>
                </div>
            </section>  
            <OurlatestTwoSection />  
            <section className="sectionCTA">
                <GetInTouch />
            </section>
        </Layout> 
    )    
}

export default ServicesPage

export const query = graphql`
  query {
    UI {
        dsService {
            Title
            Description
            OurServices {
              Approach_Text
              Approach_Heading
              ApproachLists {
                Approach_Title
                Approach_Description
                Approach_IconNum {
                  url
                  alternativeText
                }
              }
            }
            OurDeliverables {
                id
                OurDelivery_Heading
                OurDelivery_ShortDescription
                OurDeliveries {
                  Text
                  Heading
                }        
            }
            SeoTag {
                Meta_Title
                Meta_Description
            }
        }   
    }
  }
`